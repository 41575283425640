.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #007bff; /* Cor do cabeçalho */
    color: white;
    margin-bottom: 40px;
  }
  
  .header h1 {
    margin: 0;
    font-size: 24px;
  }
  
  .header button {
    margin-left: 10px;
    padding: 8px 12px;
    font-size: 16px;
    color: white;
    background-color: #0056b3;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .header button:hover {
    background-color: #004494;
  }
  