.chat-container {
  width: 320px;
  height: 460px;
  border: 2px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f9f9f9;
  margin: 0 auto; /* Centraliza horizontalmente */
  margin-top: 20px; /* Espaço superior */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra sutil */
}

.chat-title {
  text-align: center;
  margin: 10px 0;
  font-size: 24px;
  color: #333;
}

.chat-box {
  flex-grow: 1;
  padding: 10px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.input-box {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
}

input {
  flex-grow: 1;
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

button {
  margin-left: 10px;
  padding: 10px 15px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #007bff; 
}
